@font-face {
    font-family: 'CarmenSansBold';
    src: url('./assets/fonts/CarmenSansBold.otf') format('opentype');
}

@font-face {
    font-family: 'CarmenSans';
    src: url('./assets/fonts/CarmenSansMedium.otf') format('opentype');
}

@font-face {
    font-family: 'CarmenSansThin';
    src: url('./assets/fonts/CarmenSans-Thin.woff') format('woff');
}

@font-face {
    font-family: 'CarmenSansLight';
    src: url('./assets/fonts/CarmenSans-UltraLight.woff') format('woff');
}

@font-face {
    font-family: 'CarmenSansRegular';
    src: url('./assets/fonts/CarmenSans-Regular.woff') format('woff');
}

:root {
    font-family: CarmenSans, CarmenSansBold, CarmenSansThin, CarmenSansLight, CarmenSansRegular;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, CarmenSans, CarmenSansBold, CarmenSansThin, CarmenSansLight, CarmenSansRegular, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
p,
span,
label,
a,
textarea,
input,
div {
    font-family: CarmenSans !important;
}

code {
    font-family: CarmenSans;
}

.flex {
    display: flex;
}

.mb-20 {
    margin-bottom: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.ant-menu-item-selected {
    border-right: none !important;
}

.global-btn {
    background: #006641;
}

.global-btn:hover {
    background: #006641 !important;
    color: #ffffff !important;
}

.global-btn:disabled {
    background: none !important;
    color: rgb(229, 225, 225) !important;
}

.ant-pagination-item-active {
    background: #006641 !important;
}

.ant-popconfirm-buttons .ant-btn-primary {
    background: #006641;
}

.ant-popconfirm-buttons .ant-btn-primary:hover {
    background: #006641 !important;
}

.ant-picker-ok .ant-btn {
    background: #006641 !important;
    color: #ffffff !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #006641 !important;
}

.ant-tabs-tab:hover {
    color: #006641 !important;
}

.ant-tabs-ink-bar {
    background: #006641 !important;
}

.ant-spin-dot-item {
    background-color: #006641 !important;
}

.ant-modal-footer .ant-btn-primary {
    background-color: #006641 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #bc8c09 !important;
    border-color: #bc8c09;
}
.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #bc8c09 !important;
    border-color: #bc8c09 !important;
}
.ant-checkbox-checked:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #bc8c09 !important;
    border-color: #bc8c09 !important;
}
.ant-checkbox-checked:after {
    border-color: #bc8c09 !important;
}
.ant-checkbox:hover::after {
    border-color: #bc8c09 !important;
}
.ant-checkbox:hover .ant-checkbox-inner {
    border-color: #bc8c09 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #bc8c09 !important;
}

.justifyFlexEnd {
    justify-content: flex-end;
}

.justifyCenter {
    justify-content: center;
}

.justifySpaceBetween {
    justify-content: space-between;
}

.ant-menu-submenu-arrow {
    color: #006641 !important;
}

.ant-menu-inline-collapsed-tooltip {
    display: none;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

Track ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px #8b8b89;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #e4dfd6;
}

.pointer {
    cursor: pointer;
}

.bold {
    font-weight: bold;
}

.f-9 {
    font-size: 9px;
}

.f-10 {
    font-size: 10px;
}

.f-11 {
    font-size: 11px;
}

.f-12 {
    font-size: 12px;
}

.f-13 {
    font-size: 13px;
}

.f-14 {
    font-size: 14px;
}

.f-16 {
    font-size: 16px;
}

.f-20 {
    font-size: 20px;
}

.divider {
    padding: 0;
    margin: 10px 0 -5px 0;
}

.color-green {
    color: #00a96c;
}

.color-red {
    color: #ff0000;
}

.custom-menu-item {
    color: #006641 !important;
    font-family: CarmenSansRegular;
}

.ant-dropdown-menu-item-group-title {
    color: #000000 !important;
}

.ant-dropdown-menu-title-content {
    color: #000000;
}

.ant-dropdown-menu-item-divider {
    margin: 0 8px !important;
    height: 1.5px !important;
}

.full-width-tabs .ant-tabs-nav {
    display: flex;
    justify-content: space-between;
}
.full-width-tabs .ant-tabs-tab {
    flex-grow: 1;
    text-align: center;
    max-width: none;
    justify-content: center;
}

.ant-tabs-nav-list {
    width: 100%;
}

@media (max-width: 1240px) {
    .ant-tabs-nav-list {
        width: unset !important; /* Adjust width as needed */
    }
}

/* .ant-dropdown-menu-item {
    padding-top: 0 !important;
} */

.ant-dropdown-menu-item-group-title {
    padding: 7px 20px 0 20px !important;
}

.custom-btn {
    padding: 0 15px !important;
    height: 36px !important;
    border-radius: 20px;
    border: 1px solid #d4d4d4 !important;
    font-family: CarmenSansRegular;
}

.ant-dropdown-menu-item-group-title {
    font-weight: bold !important;
}

textarea::-webkit-scrollbar-track {
    background: transparent; /* Optional: Background of the track */
    margin: 10px 0; /* Add padding above and below the scrollbar */
}

textarea::-webkit-scrollbar-thumb {
    border-radius: 4px; /* Rounded corners */
}

.ant-input-number-handler-wrap {
    display: none !important;
}

.custom-label-position {
    position: absolute;
    left: 20px;
}

.verify-later .ant-modal-content {
    border-radius: 7px !important;
    padding: 40px 30px 30px 30px;
}

.verify-later .ant-modal-title {
    color: #111827 !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.82px;
}

.custom-dropdown .rc-virtual-list-holder {
    max-height: none !important;
}
